import { Component, OnInit } from "@angular/core";
import * as R from "ramda";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { GAME_CONFIG } from "../../../../../../../../core/config/custom/_parsed-game.config";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { OwDate } from "../../../../../../../../core/utility/ow-date.class";
import { OwPaginationDate } from "../../../../../../../../core/utility/ow-pagination.class";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { UserService } from "../../../../../../../user/providers/user.service";
import { MyIdObject } from "../../../../../../interfaces/custom/rank.interface";
import { ApiBusinessRankService } from "../../../../../san-ranks/api/service/api-business-rank.service";
import { ApiGameRanksService } from "../../../../../san-ranks/api/service/api-game-ranks.service";
import { RankingDetailsGame } from "../../../../../san-ranks/basic/custom/dialogs/rank-details/ranking-game-details/ranking-details-game.component";
import { RankFilter } from "../../../../../san-ranks/basic/custom/dialogs/ranks/interfaces/rank-filter.interface";
import { RankGame } from "../../../../../san-ranks/interfaces/custom/rank-game.interface";
import { FILTERS } from "./consts/filters.const";
import { FILTERS_CC } from "./consts/filters-cc.const";

@Component({
  selector: "wf-rank",
  templateUrl: "./wf-rank.component.html",
})
export class WfRankComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(UserService) userService: UserService;
  @OwInject(ApiBusinessRankService) apiBusinessRankService: ApiBusinessRankService;
  @OwInject(ApiGameRanksService) apiGameRanksService: ApiGameRanksService;
  isCC = GAME_CONFIG.IS_CC;

  rankListGame: RankGame[] = [];
  activeFilter: RankFilter;
  filters: RankFilter[] = this.isCC ? FILTERS_CC : FILTERS;

  owPaginationDate: OwPaginationDate;
  _owDate: OwDate;
  set owDate(owDate: OwDate) {
    this._owDate = owDate;
    this.owPaginationDate = new OwPaginationDate({ current: this.owDate });
  }

  get owDate(): OwDate {
    return this._owDate;
  }

  myIdObject: MyIdObject;

  ngOnInit() {
    this.myIdObject = {
      player_id: this.playerService.player.id,
      branch_id: this.playerService.player.branch_id,
      region_id: this.playerService.player.region_id,
      partner_id: this.userService.me.partner_id,
      title_id: this.playerService.player.title_id.id,
    };
    this.changeFilter(this.filters[0]);
    this.clearOwDate();
    this.getGameRanks();
  }

  clearOwDate() {
    this.owDate = new OwDate();
  }

  getGameRanks() {
    this.apiGameRanksService
      .getGameRanks({
        location: 4,
      })
      .subscribe(
        resp => {
          this.rankListGame = resp;
          // #31883
          if (!this.isCC) {
            for (const item of this.rankListGame) {
              const elName = item.name.toLowerCase();

              if (elName.includes("kulinarn")) {
                item.award_icon = "ui_pot.png";
                continue;
              }

              if (elName.includes("regene")) {
                item.award_icon = "ui_lotos_flower.png";
                continue;
              }

              item.award_icon = "ui_logo_ranging.png";
            }
          }
        },
        errResp => {
          this.rankListGame = [];
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
  }

  openRankGameDetails(rank: RankGame) {
    if (R.path(["parameters", "tooltipInList"], rank)) {
      return;
    }

    this.dialogService.open(RankingDetailsGame, {
      data: {
        rankId: rank.rank_edition_id,
        group: this.activeFilter.valueApi,
        isWfRank: true,
      },
    });
  }

  changeFilter(filter: RankFilter): void {
    this.activeFilter = R.clone(filter);
  }
}
