<div class="options loading-opacity">
  <!-- FILTERS -->
  <div class="filters" *ngIf="activeFilter">
    <ng-container *ngFor="let filter of filters">
      <div class="ow-radio-container" *ngIf="filter.show">
        <label class="choose-wrapper">
          <span class="checkbox-wrapper">
            <input
              type="radio"
              name="ranksFilterWf"
              (change)="changeFilter(filter)"
              [checked]="activeFilter.valueApi === filter.valueApi"
            >
            <span class="checkmark"></span>
          </span>
          <span class="text-wrapper">
            {{ filter.label }}
          </span>
        </label>
      </div>
    </ng-container>
  </div>
</div>

<ow-perfect-scrollbar [scrollGradient]="rankListGame">
  <table>
    <tbody>
    <!-- RANKS GAME -->
    <ng-container *ngFor="let rank of rankListGame">
      <tr *ngIf="(!isCC && (!rank?.parameters?.show_only || rank.parameters.show_only === activeFilter.valueApi)) ||
      (isCC && (!rank?.parameters?.show_only || rank.parameters.show_only === activeFilter.valueApi))">
        <td
          (click)="openRankGameDetails(rank)"
          [ngbTooltip]="rank.parameters && (rank.parameters.tooltipInList || rank.parameters.tooltipInListWithoutBlock)"
          container="body"
          placement="bottom-left"
          tooltipClass="rank pre-line"
        >
          <span>{{ rank.name }}</span>
          <img *ngIf="rank.award_icon" [src]="rank.award_icon | asset: 'ui'" />
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</ow-perfect-scrollbar>
